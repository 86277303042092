<template>
  <v-card
    flat
    :outlined="outlined"
    :min-width="minWidth"
    width="100%"
    :class="{ 'outlined': outlined }"
    class="data-card d-flex flex-column rounded-lg"
  >
    <div ref="header">
      <slot 
        name="header"
      >
        <v-card-actions 
          ref="title"
          class="pt-4 flex-shrink-0 justify-space-between"
          :style="{ 'cursor': toggleable ? 'pointer' : 'default' }"
          @click="toggle(!mini)"
        >
          <h2 class="card-title font-weight-medium pa-1 pl-2">
            {{ title }}
          </h2>
          <v-btn
            v-if="toggleable&&!loading"
            icon
            right
            @click.stop="toggle(!mini)"
          >
            <v-icon>{{ mini ? icons.mdiChevronDown : icons.mdiChevronUp }}</v-icon>
          </v-btn>
          <v-progress-circular
            v-else-if="loading"
            indeterminate
            size="24"
            width="3"
            color="grey lighten-3"
            class="loading mr-4"
          />
        </v-card-actions>
      </slot>
    </div>
    <v-expand-transition>
      <v-card-text 
        v-if="!mini"
        ref="content"
        class="card-content pa-0 scrollable flex-grow-1 flex-shrink-1 d-flex flex-column"
      >
        <div 
          v-if="numbers!=null"
          ref="numbers"
          class="numbers d-flex pt-4 px-4 pb-8"
        >
          <div
            v-for="(number, i) in numbers"
            :key="`numbers-${i}`"
            class="number flex-grow-1"
            :class="[`text-${number.value.hasOwnProperty('align') ? number.value.align : 'left'}`]"
          >
            <h3
              class="value text-h4 text-lg-h3 font-weight-black mb-2"
              :class="[`${number.value.color}--text`]"
              :style="{ 'color': (number.value.color.indexOf('#')>=0 ? number.value.color : '') }"
            >
              {{ number.value.data | format(number.value.format) }}
            </h3>
            <span 
              class="title text-caption font-weight-bold"
            >
              {{ number.title }}
              <v-tooltip 
                v-if="number.hint!=null"
                right
                open-delay="200"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="grey lighten-1"
                    small
                    class="ml-1"
                  >
                    {{ icons.help }}
                  </v-icon>
                </template>
                <span>{{ number.hint }}</span>
              </v-tooltip>
            </span>
            <span 
              v-if="number.secondary!=null&&number.secondary.data!=null"
              :class="[ number.secondary.colored ? secondaryColor(number.secondary) : 'grey--text text--darken-1' ]"
              class="secondary-number body-2 font-weight-medium"
            >
              <v-icon
                v-if="number.secondary.colored"
                small
                :class="[ number.secondary.colored ? secondaryColor(number.secondary) : 'grey--text text--darken-1' ]"
                style="margin-top: -2px !important;"
              >
                {{ secondaryIcon(number.secondary) }}
              </v-icon>
              {{ number.secondary.data | numeral(number.secondary.format) }}
            </span>
          </div>
        </div>
        <v-scroll-y-reverse-transition>
          <loading 
            v-if="loading&&chart.type!='SimpleTable'&&chart.type!='HourlyHeatmap'"
          />
          <GChart
            v-else-if="(chart.type=='LineChart'||chart.type=='AreaChart'||chart.type=='ColumnChart'||chart.type=='BarChart'||chart.type=='PieChart')&&ready"
            :type="chart.type"
            :data="chart.data"
            :options="chartOptions"
            class="chart line column pa-4 pt-0 flex-grow-1 flex-shrink-1"
          />
          <hourly-heatmap
            v-else-if="chart.type=='HourlyHeatmap'"
            :data="chart.data"
            :metric="metric"
            :label="chart.label"
            :format="chart.format"
            :inverted="chart.inverted"
            :height="views.chart"
            class="chart heatmap pa-4 pt-0 flex-grow-1 flex-shrink-1"
          />
        </v-scroll-y-reverse-transition>
        <simple-table
          v-if="chart.type=='SimpleTable'"
          :data="chart.data"
          :headers="chart.headers"
          :metric="metric"
          :tab="tab"
          :loading="loading"
          :height="views.chart"
          :selectable="selectable"
          class="chart simple-table pt-0 flex-grow-1 flex-shrink-1"
          @tab-change="onTabChange"
          @metric-change="onMetricChange"
          @select-item="onItemSelected"
        />
        <v-overlay
          v-model="empty"
          absolute
          color="white"
        />
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<style>

  .data-card {
    overflow: hidden;
  }
  .data-card:not(.outlined) {
    filter: drop-shadow(0px 4px 12px rgba(61, 75, 143, 0.16));
  }

  .data-card .card-title {
    font-size: 1.125rem !important;
  }
  .data-card .card-title, .data-card .numbers .title {
    color: var(--mobees-black);
    opacity: .6;
  }
  .data-card .numbers .number {
    flex-basis: 0;
  } 

  .data-card .secondary-number {
    font-size: .875em !important;
  }

  .data-card .placeholder.loading {
    animation: loading 2s infinite ease-in-out;
  }

  .data-card .card-content {
    position: relative;
  }

  @keyframes loading {
    0% {
      opacity: .4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: .4;
    }
  }
  
</style>

<script>
  import { mdiHelpCircleOutline, mdiArrowUp, mdiArrowDown, mdiChevronDown, mdiChevronUp } from '@mdi/js'
  import numeral from 'numeral'

  export default {

    components: {
      HourlyHeatmap: () => import('@/components/HourlyHeatmap'),
      SimpleTable: () => import('@/components/SimpleTable'),
      Loading: () => import('@/components/ChartLoading'),
    },

    props: {
      title: {
        type: String,
        default: 'Título'
      },
      numbers: {
        type: Object,
        default: () => {
          return null
        },
      },
      chart: {
        type: Object,
        default: () => {
          return {
            type: null,
            inverted: false,
            data: null
          }
        }
      },
      metric: {
        type: String,
        default: null,
      },
      tab: {
        type: String,
        default: null,
      },
      mini: {
        type: Boolean,
        default: false
      },
      outlined: {
        type: Boolean,
        default: false
      },
      toggleable: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false,
      },
      empty: {
        type: Boolean,
        default: false,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      maxHeight: {
        type: [Number, String],
        default: '50vh'
      },
      fitHeight: {
        type: Boolean,
        default: false
      },
      minWidth: {
        type: Number,
        default: 320
      },
      length: {
        type: Number,
        default: 30
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
        up: mdiArrowUp,
        down: mdiArrowDown,
        mdiChevronDown, 
        mdiChevronUp
      },
      ready: false,
      settings: {
        language: 'br'
      },
      default: {
        options: {
          chartArea: {
            width: '100%', 
            height: '90%',
          },
          height: 400,
          lineWidth: 4,
          pointSize: 6,
          pieHole: 0.5,
          legend: { 
            position: 'none' 
          },
          hAxis: {
            textPosition: 'out',
            gridlines: {
              count: 8,
              color: '#eee',
              minSpacing: 40,
            },
            baselineColor: '#ddd',
            minorGridlines: {
              color: 'transparent'
            },
            textStyle: { 
              color: '#888',
              fontName: 'inherit',
              fontSize: 12,
            },
            minTextSpacing: 4,
            maxTextLines: 2,
            showTextEvery: 2,
            slantedText: false
          },
          vAxis: {
            textPosition: 'in',
            format: 'short',
            gridlines: {
              count: 8,
              color: '#eee',
              minSpacing: 40,
            },
            baselineColor: '#ddd',
            minorGridlines: {
              color: 'transparent'
            },
            textStyle: { 
              color: '#888',
              fontName: 'inherit',
              fontSize: 12,
            }
          },
          isStacked: false,
          tooltip: {
            textStyle: { 
              color: '#666',
              fontSize: 14,
            }
          },
          colors: [
            '#698DF2',
            '#F49891',
          ],
          animation: {
            startup: true,
            duration: 250,
            easing: 'in-out',
          },
          // explorer: {
          //   actions: ['dragToZoom', 'rightClickToReset'],
          //   axis: 'horizontal',
          //   keepInBounds: true,
          //   maxZoomIn: 4.0
          // }
        }
      },
      animation: {
        toggle: false,
        data: null,
        interval: 250,
        controller: null,
        options: {
          vAxis: {
            gridlines: {
              color: 'transparent',
            },
            textPosition: 'none',
            baselineColor: '#ddd',
            viewWindow: {
              min: 0,
              max: 100
            }
          },
          hAxis: {
            gridlines: {
              color: 'transparent',
            },
            baselineColor: 'transparent',
            textPosition: 'none'
          },
          colors: [
            '#ddd',
            '#eee',
          ],
        }
      },
      views: {
        header: null,
        numbers: null,
        chart: null,
        content: null,
      },
      resizer: {
        delay: 1000,
        resizing: false,
        timer: null
      }
    }),

    computed: {
      chartOptions () {
        return this.animation.toggle ? _.merge({}, this.default.options, this.animation.options) : _.merge({}, this.default.options, this.chart.options);
      },

      // headerHeight () {
      //   const title = _.has(this.$refs, 'title') ? this.$refs.title.$el.getBoundingClientRect().height : 52;
      //   const numbers = _.has(this.$refs, 'numbers') ? this.$refs.numbers.$el.getBoundingClientRect().height : 128;
      //   return this.numbers == null ? title : title + numbers;
      // },
      // contentHeight () {
      //   const height = this.maxHeight - this.views.header - this.views.numbers;
      //   console.log('height', height);
      //   return height;
      // },

      placeholder () {
        return this.loading || this.chart.data == null;
      }
    },

    methods: {
      onMetricChange (m) {
        this.$emit('metric-change', m);
      },

      onTabChange (tab) {
        this.$emit('tab-change', tab);
      },

      onItemSelected (item) {
        this.$emit('select-item', item);
      },

      toggle (b) {
        this.$emit('toggle', {
          toggle: b, 
          view: 'hotspots'
        });
      },

      secondaryIcon (item) {
        return item.data <= 0 ? this.icons.down : this.icons.up;
      },

      secondaryColor (item) {
        item.inverted = _.isNil(item.inverted) ? false : item.inverted;
        const direction = item.inverted ? item.data >= 0 : item.data <= 0;
        return direction ? 'error--text' : 'success--text';
      },

      onResize () {
        if (!this.resizer.resizing&&_.has(this.$refs, 'header')) {
          const max = _.isString(this.maxHeight) ? (parseInt(this.maxHeight)/100)*window.innerHeight : this.maxHeight;
          this.resizer.resizing = true;
          const header = _.has(this.$refs, 'header') ? this.$refs.header.getBoundingClientRect() : { height: 0 };
          const numbers = _.has(this.$refs, 'numbers') ? this.$refs.numbers.getBoundingClientRect() : { height: 0 };
          // const content = _.has(this.$refs, 'content') ? this.$refs.content.getBoundingClientRect() : { height: 0 };
          this.views.header = header.height;
          this.views.numbers = numbers.height;
          let height = max - this.views.header - this.views.numbers;
          // if (this.fitHeight&&content.height>0&&content.height<height) height = content.height;
          // console.log('height', height);
          this.default.options.height = this.views.chart = height;
          this.resizer.resizing = false;
          this.resizer.timer = clearTimeout(this.resizer.timer);
        }else{
          this.resizer.timer = setTimeout(($) => {
            $.onResize();
          }, this.resizer.delay, this);
        }
      }
    },

    watch: {
      chart: {
        immediate: true,
        deep: true,
        handler (chart) {
          this.onResize();
        }
      },
      maxHeight: {
        immediate: true,
        handler (h) {
          this.onResize();
        }
      }
    },

    filters: {
      format (value, format) {
        const formatted = numeral(value).format(format);
        return value==null ? '-' : formatted.indexOf(':')>=0 && formatted.length>=8 ? numeral(value/60/60).format('0,0[.]0') + ' h' : formatted;
      }
    },

    mounted () {
      setTimeout(() => {
        this.ready = true;
        this.onResize();
      }, 100);
    },
    beforeDestroy () {
      if (this.resizer.timer!=null) this.resizer.timer = clearTimeout(this.resizer.timer);
    },
  }
</script>